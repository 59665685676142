import axios from 'axios'
import { useEffect, useState } from 'react'
import { BaseApi } from './baseApi'

export const AgencyDetail = (array: any) => {
  const token = window.localStorage.getItem('token')

  useEffect(() => {
    axios({
      method: 'POST',
      url: `${BaseApi}/api/get_detail`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authorization: `Bearer ${token}`,
        token: `${token}`,
      },
      data: {
        item: array.cellItem.cellItem,
        customer: array.cellCustomer.cellCustomer,
        date: array.cellDate.cellDate,
      },
    })
      .then(async (res) => {
        localStorage.setItem('details', JSON.stringify(await res.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
}
