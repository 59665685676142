import axios from 'axios'
import { AgencyDrivers } from './agencyDrivers'
import { BaseApi } from './baseApi'

export const SignInApi = async (email: string, password: string) => {
  await axios({
    method: 'POST',
    url: `${BaseApi}/api/login`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: {
      account: email,
      pw: password,
    },
  })
    .then(async (res) => {
      localStorage.setItem('token', res.data.token)
    })
    .catch((error) => {
      console.log(error)
    })
}
