import axios from 'axios'
import { dataWarpper } from '../components/detail/style'
import { BaseApi } from './baseApi'
export const AgencyTotal = (s: any, e: any, n: any) => {
  const token = window.localStorage.getItem('token')
  axios({
    method: 'POST',
    url: `${BaseApi}/api/agency_get_total`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      authorization: `Bearer ${token}`,
      token: `${token}`,
    },
    data: {
      startDay: s,
      endDay: e,
      storeName: n,
    },
  })
    .then(async (res) => {
      console.log(res)
      localStorage.setItem(
        'dworkload',
        JSON.stringify(await res.data.resultArray)
      )
      localStorage.setItem('total_cnt', await res.data.total_cnt)
      localStorage.setItem('total', await res.data.total)
      localStorage.setItem('supply', await res.data.supply)
      localStorage.setItem('surtax', await res.data.surtax)
    })
    .catch((error) => {
      console.log(error)
    })
}
