import styled from '@emotion/styled'

export const CalculatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  border: 1px solid #0075ff;
`

export const textWrapper = styled.div`
  margin-left: 1vw;
  margin-right: 1vw;
`
