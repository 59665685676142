import * as S from './style'

const onClick = (cell: any) => {
  window.localStorage.removeItem('details')
  localStorage.setItem('cellItem', cell.item)
  localStorage.setItem('cellCustomer', cell.customer)
  localStorage.setItem('cellDate', cell.date)
  localStorage.setItem('reload', '')
  const openWin = window.open(
    '/Detail',
    'pop01',
    'left=350vw, width=900, height=600,fullscreen=no, '
  )
}

export const COLUMNS = [
  {
    Header: '영수증번호',
    accessor: 'item',
  },
  {
    Header: '배송상태',
    accessor: 'action',
  },
  {
    Header: '드라이버명',
    accessor: 'name',
  },
  {
    Header: '매장명',
    accessor: 'storeName',
  },
  {
    Header: '출발지',
    accessor: 'depart',
  },
  {
    Header: '도착지',
    accessor: 'arrival',
  },
  {
    Header: '날짜',
    accessor: 'date',
  },

  {
    Header: '출발시간',
    accessor: 'depart_time',
  },

  {
    Header: '도착시간',
    accessor: 'arrival_time',
  },

  {
    Header: '요금',
    accessor: 'charge',
  },

  {
    Header: '고객명',
    accessor: 'customer',
  },

  {
    Header: '상새정보',
    accessor: 'information',
    Cell: function (props: any) {
      const rowData = props.row.original

      return <S.infoBtn onClick={() => onClick(rowData)}>상세정보</S.infoBtn>
    },
  },
]
