export const DworkloadCOLUMNS = [
  {
    Header: '매장명',
    accessor: 'store_Name',
  },

  {
    Header: '날짜',
    accessor: 'date',
  },
  {
    Header: '업무량',
    accessor: 'cnt',
  },
]
