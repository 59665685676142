import * as S from './style'

export const Calculater = () => {
  return (
    <>
      <h3>총 업무량: {window.localStorage.getItem('total_cnt')}건</h3>
      <S.CalculatorDiv>
        <S.textWrapper>
          <p>합산 금액(원)</p>
          <p>{window.localStorage.getItem('total')}</p>
        </S.textWrapper>
        <S.textWrapper>
          <p>=</p>
          <p>=</p>
        </S.textWrapper>

        <S.textWrapper>
          <p>배송 요금</p>
          <p>{window.localStorage.getItem('supply')}</p>
        </S.textWrapper>
        <S.textWrapper>
          <p>+</p>
          <p>+</p>
        </S.textWrapper>
        <S.textWrapper>
          <p>부가세</p>
          <p>{window.localStorage.getItem('surtax')}</p>
        </S.textWrapper>
      </S.CalculatorDiv>
    </>
  )
}
