import React, { useMemo, useEffect, useState } from 'react'
import { AgencyDetail } from '../../api/agencyDetail'

import * as S from './style'

function Detail() {
  const cellItem = window.localStorage.getItem('cellItem')
  const cellCustomer = window.localStorage.getItem('cellCustomer')
  const cellDate = window.localStorage.getItem('cellDate')

  const array = {
    cellItem: { cellItem },
    cellCustomer: { cellCustomer },
    cellDate: { cellDate },
  }
  AgencyDetail(array)

  useEffect(() => {}, [])
  const [DData, setDData] = useState([{}])
  const dd = window.localStorage.getItem('details')
  useEffect(() => {
    if (dd !== null) {
      setDData(JSON.parse(dd))
    } else {
    }
  }, [])

  const Reload = () => {
    window.location.reload()
  }
  if (window.localStorage.getItem('reload') !== 'no') {
    setTimeout(function () {
      window.location.reload()
    }, 1000)
    localStorage.setItem('reload', 'no')
  }

  console.log(DData)
  const actionDiv = DData.map((data: any) => <div>{data.action}</div>)
  const addressDiv = DData.map((data: any) => <div>{data.address}</div>)
  const arrivalDiv = DData.map((data: any) => <div>{data.arrival_time}</div>)
  const customerDiv = DData.map((data: any) => <div>{data.customer}</div>)
  const dateDiv = DData.map((data: any) => <div>{data.date}</div>)
  const deliveredDiv = DData.map((data: any) => (
    <div>{data.delivered_type}</div>
  ))
  const departDiv = DData.map((data: any) => <div>{data.depart_time}</div>)
  const detailDiv = DData.map((data: any) => <div>{data.detail}</div>)
  const finishDiv = DData.map((data: any) => (
    <img
      src={data.finish_image}
      style={{ width: '37vw', height: '65vh' }}
    ></img>
  ))
  const itemDiv = DData.map((data: any) => <div>{data.item}</div>)
  const memoDiv = DData.map((data: any) => <div>{data.memo}</div>)
  const nameDiv = DData.map((data: any) => <div>{data.name}</div>)
  const phoneDiv = DData.map((data: any) => <div>{data.phone}</div>)
  const telDiv = DData.map((data: any) => <div>{data.tel}</div>)
  return (
    <>
      <S.topDiv>
        <S.dataWarpper>영수증 번호: &nbsp; {itemDiv}</S.dataWarpper>
        <S.checkButton onClick={Reload}>새로고침</S.checkButton>
        <S.dataWarpper>{dateDiv}</S.dataWarpper>
      </S.topDiv>
      <S.HeaderBottomBar></S.HeaderBottomBar>
      <S.detailDiv>
        <S.imageDiv>
          <S.dataWarpper>
            {actionDiv}
            {deliveredDiv}
          </S.dataWarpper>
          <S.dataWarpper></S.dataWarpper>
          <S.dataWarpper>{finishDiv}</S.dataWarpper>
          <S.dataWarpper>
            출발시간:{departDiv} 도착시간:{arrivalDiv}
          </S.dataWarpper>
        </S.imageDiv>

        <S.infoDiv>
          <S.dataWarpper>고객: {customerDiv}</S.dataWarpper>
          <S.dataWarpper>배송지: {addressDiv}</S.dataWarpper>
          <S.dataWarpper>상세 주소:{detailDiv}</S.dataWarpper>
          <S.dataWarpper>고객 연락처: {telDiv}</S.dataWarpper>
          <S.dataWarpper>요청사항: {memoDiv}</S.dataWarpper>
          <br></br>
          <S.dataWarpper>드라이버: {nameDiv}</S.dataWarpper>
          <S.dataWarpper>드라이버 연락처: {phoneDiv}</S.dataWarpper>
        </S.infoDiv>
      </S.detailDiv>
    </>
  )
}

export default Detail
