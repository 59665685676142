import styled from '@emotion/styled'
export const detailDiv = styled.div`
  display: flex;
  height: 95vh;
  justify-content: space-between;
  background: #e5f1ff;
`
export const topDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
`

export const imageDiv = styled.div`
  height: 80vh;

  margin-left: 5vw;
  margin-top: 3vh;
  width: 40vw;

  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
`

export const infoDiv = styled.div`
  height: 80vh;

  margin-right: 5vw;
  margin-top: 3vh;
  width: 40vw;

  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: white;
`

export const dataWarpper = styled.div`
  display: flex;
  margin: 2vh;
  font-size: 16px;
  justify-content: space-between;
`
export const HeaderBottomBar = styled.div`
  background: #cdd5df;
  width: 99vw;
  height: 1px;
  z-index: 999;
  margin-left: calc(-50vw + 50%);
`

export const checkButton = styled.button`
  width: 8vw;
  height: 5vh;
  border-radius: 8px;
  border: 1px solid #0075ff;
  background: #0075ff;
  color: white;

  &:hover {
    border: 1px solid #0075ff;
    border-radius: 8px;
    color: #0075ff;
    background: none;
    box-sizing: border-box;
  }
`
