import { useState } from 'react'
import Header from '../common/header'
import Menu from '../common/menu'
import PaginationTable from '../common/table/PaginationTable'
import * as S from './style'
import { Store } from '../../api/store'
import { AgencyTotal } from '../../api/agencyTotal'
import { useEffect } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './dateStyle.css'
import { Calculater } from './calculator'

function DWorkload() {
  const [SelectedSDate, setSelectedSDate] = useState<Date | null>(new Date())
  const [SelectedEDate, setSelectedEDate] = useState<Date | null>(new Date())
  useEffect(() => {
    AgencyTotal(SelectedSDate, SelectedEDate, selected)
  }, [])

  const ss = () => {
    AgencyTotal(SelectedSDate, SelectedEDate, selected)
  }
  const dworkload = 'dworkload'
  const stores = Store()
  const storeName =
    stores &&
    stores.map((store: any) => (
      <option value={store.value}>{store.label}</option>
    ))

  const Reload = () => {
    window.location.reload()
  }
  const [selected, setSelected] = useState(-1)

  const handleSelect = (e: any) => {
    setSelected(e.target.value)
  }
  return (
    <>
      <S.MainPageLayout>
        <Header />
        <Menu />
        <S.MainContentWrapper>
          <S.agencyH1>일별 업무량 조회</S.agencyH1>
          <S.InputWrapper>
            <h3>매장명</h3>
            <S.storeSelect onChange={handleSelect}>{storeName}</S.storeSelect>
          </S.InputWrapper>
          <S.InputWrapper>
            <h3>시작일</h3>
            <DatePicker
              dateFormat="yyyy-MM-dd" // 날짜 형태
              shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
              selected={SelectedSDate}
              onChange={(date) => setSelectedSDate(date)}
              className="datePicker"
              onCalendarClose={ss}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <h3>종료일</h3>
            <DatePicker
              dateFormat="yyyy-MM-dd" // 날짜 형태
              shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
              selected={SelectedEDate}
              onChange={(date) => setSelectedEDate(date)}
              className="datePicker"
              onCalendarClose={ss}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <h3>agency</h3>
            <S.agenchInput
              readOnly
              placeholder="로지웨이주식회사"
            ></S.agenchInput>
          </S.InputWrapper>
          <S.InputWrapper>
            <S.checkButton onClick={Reload}>조회하기</S.checkButton>
          </S.InputWrapper>
          <S.InputWrapper>
            <Calculater />
          </S.InputWrapper>

          <PaginationTable pagename={dworkload} />
        </S.MainContentWrapper>
      </S.MainPageLayout>
    </>
  )
}

export default DWorkload
